var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"block-comments mt-4"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("комментарии")])],1),_c('v-slide-y-transition',[_c('div',{staticClass:"comment-add"},[(
            _vm.currentUser &&
            _vm.siteSettings &&
            _vm.siteSettings.blank_comments &&
            _vm.siteSettings.blank_comments.length > 0
          )?_c('div',{staticClass:"blank_comments"},[_c('v-subheader',[_vm._v("Воспользуйтесь готовым комментарием")]),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.siteSettings.blank_comments),function(comment,index){return _c('v-list-item',{key:index,staticClass:"mt-1 blank-cooment-item",attrs:{"index":index},on:{"click":function($event){return _vm.addBlankComment(comment)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(comment))])],1)],1)}),1)],1)],1):_vm._e(),(_vm.currentUser)?_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"name":"inputComment","label":"Или напишите свой комментарий здесь","value":"","rules":_vm.commentTextRules},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}}),_c('v-btn',{attrs:{"dark":"","color":"primary","loading":_vm.loadingAddComment},on:{"click":_vm.submitComment}},[_vm._v("Добавить")])],1):_c('div',{staticClass:"text-center"},[_vm._v(" Вы должны "),_c('v-btn',{attrs:{"to":{
              name: 'Login',
              query: { return_url: _vm.$route.fullPath },
            },"tile":"","link":"","text":""}},[_vm._v("авторизоваться")])],1)],1)]),(_vm.comments && _vm.comments.length > 0)?_c('div',{staticClass:"comments"},[_c('comment-list',{attrs:{"comments":_vm.comments,"entity_user_id":_vm.entity_user_id,"commentable_type":_vm.commentable_type}}),(_vm.comments_pages > 1)?_c('v-pagination',{staticClass:"mt-2",attrs:{"length":_vm.comments_pages,"total-visible":7},on:{"input":_vm.goPage},model:{value:(_vm.comments_page),callback:function ($$v) {_vm.comments_page=$$v},expression:"comments_page"}}):_vm._e()],1):_c('div',{staticClass:"comments"},[_c('no-content',{attrs:{"title":"Комментариев нет"}})],1)],1),_c('v-dialog',{staticClass:"text-center",attrs:{"max-width":"290"},model:{value:(_vm.dialogDeleteComment),callback:function ($$v) {_vm.dialogDeleteComment=$$v},expression:"dialogDeleteComment"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v("Вы уверены?")]),_c('v-card-text',[_vm._v("Что хотите удалить данную запись.")]),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteComment}},[_vm._v("Да")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDeleteComment = false}}},[_vm._v("Нет")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }