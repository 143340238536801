<template>
  <div>
    <v-card class="block-comments mt-4">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>комментарии</v-toolbar-title>
        <!-- <v-spacer></v-spacer>
        <v-btn icon @click="showCommentAdd = !showCommentAdd">
          <v-icon>$vuetify.icons.plus</v-icon>
        </v-btn> -->
      </v-toolbar>
      <v-slide-y-transition>
        <!-- <div v-if="showCommentAdd" class="comment-add"> -->
        <div class="comment-add">
          <div
            class="blank_comments"
            v-if="
              currentUser &&
              siteSettings &&
              siteSettings.blank_comments &&
              siteSettings.blank_comments.length > 0
            "
          >
            <v-subheader>Воспользуйтесь готовым комментарием</v-subheader>
            <v-list class="pa-0" dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(comment, index) in siteSettings.blank_comments"
                  :key="index"
                  :index="index"
                  @click="addBlankComment(comment)"
                  class="mt-1 blank-cooment-item"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ comment }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>

          <v-form v-if="currentUser" v-model="valid" ref="form">
            <v-textarea
              name="inputComment"
              label="Или напишите свой комментарий здесь"
              value
              v-model="commentText"
              :rules="commentTextRules"
            ></v-textarea>
            <v-btn
              dark
              color="primary"
              :loading="loadingAddComment"
              @click="submitComment"
              >Добавить</v-btn
            >
          </v-form>
          <div class="text-center" v-else>
            Вы должны
            <v-btn
              :to="{
                name: 'Login',
                query: { return_url: $route.fullPath },
              }"
              tile
              link
              text
              >авторизоваться</v-btn
            >
          </div>
        </div>
      </v-slide-y-transition>
      <div v-if="comments && comments.length > 0" class="comments">
        <comment-list
          :comments="comments"
          :entity_user_id="entity_user_id"
          :commentable_type="commentable_type"
        ></comment-list>
        <v-pagination
          v-if="comments_pages > 1"
          @input="goPage"
          v-model="comments_page"
          :length="comments_pages"
          :total-visible="7"
          class="mt-2"
        ></v-pagination>
      </div>
      <div v-else class="comments">
        <no-content title="Комментариев нет"></no-content>
      </div>
    </v-card>
    <v-dialog v-model="dialogDeleteComment" class="text-center" max-width="290">
      <v-card>
        <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

        <v-card-text>Что хотите удалить данную запись.</v-card-text>

        <v-card-actions class="justify-space-around">
          <v-btn color="red darken-1" text @click="deleteComment">Да</v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="dialogDeleteComment = false"
            >Нет</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: [
    "route_name",
    "commentable_id",
    "commentable_type",
    "commentable_slug",
    "entity_user_id",
  ],
  data() {
    return {
      valid: true,
      page: 1,
      loadingComments: false,
      showCommentAdd: false,
      commentText: "", //Текст комментария
      commentTextRules: [
        (v) => !!v || "Поле обязательно",
        (v) => !!v === v.length >= 1 || "Минимум 1 символ",
      ],
      dialogDeleteComment: false,
      deleteCommentId: null,
      loadingAddComment: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    deleteCommentModal(commentId) {
      this.deleteCommentId = commentId;
      this.dialogDeleteComment = true;
    },
    submitComment() {
      event.preventDefault();
      if(!this.currentUser) return;
      if (!this.validate()) return;
      let app = this;
      app.loadingAddComment = true;
      this.$store
        .dispatch("comments/addComment", {
          body: app.commentText,
          commentable_id: app.commentable_id,
          parent_id: null,
          commentable_type: app.commentable_type,
        })
        .then(() => {
          app.loadingAddComment = false;
          app.clearComment();
        })
        .catch(() => {
          app.loadingAddComment = false;
        });
    },
    clearComment() {
      this.commentText = "";
      this.showCommentAdd = false;
      this.$refs.form.resetValidation();
    },
    deleteComment() {
      this.loadingComments = true;
      let app = this;
      this.$store
        .dispatch("comments/dellComment", {
          comment_id: app.deleteCommentId,
          commentable_type: "comment",
        })
        .then(() => {
          app.dialogDeleteComment = false;
        })
        .catch(() => {
          app.dialogDeleteComment = false;
        });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          params: { slug: this.commentable_slug },
          query: { page: nextPage, sort: this.sort },
        });
      }
    },
    addBlankComment(comm) {
      this.commentText = comm;
      this.loadingAddComment = true;
      this.$nextTick(() => {
        this.submitComment();
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      comments: "comments/comments",
      comments_pages: "comments/comments_pages",
      siteSettings: "siteSettings",
    }),
    comments_page: {
      get() {
        return this.$store.getters["comments/comments_page"];
      },
      set(val) {
        this.$store.commit("comments/SET_PAGE", val);
      },
    },
  },
  mounted() {},
  created() {
    this.$eventBus.$on("delete-comment", this.deleteCommentModal);
  },
  beforeDestroy() {
    if (this.$route.name != this.route_name) {
      //Костыль
      this.$eventBus.$off("delete-comment");
    }
  },
};
</script>
